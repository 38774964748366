body{
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-size: 1em;
  margin: 0;
  padding: 0;
  min-height: 100vh;

  &>div{
    position: fixed;
    top:0;
    left: 0;
    bottom: 0;
    right: 0;
    
    &>div{
      position: fixed;
      top:0;
      left: 0;
      bottom: 0;
      right: 0;
      
    }
  }
}

body.hello{
  display: flex;
  justify-content: center;
  align-items: center;

  h1{
    font-size: 5rem;
  }
}

#loading-bar{
  color: red !important;
  height: 4px !important;
}

.map {
  position: fixed;
  top:0;
  left: 0;
  bottom: 0;
  right: 0;
  background: #aad3df;

  #new {    
    position: absolute;
    bottom: 8.5em;
    right: .7em;
    padding: 2px;
    background-color: rgba(53, 54, 56, 0.5);
    width: 2em;
    height: 2em;
    z-index: 9999;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    &.draw{
      background-color: #F44336;
    }
    &::before {
      content: '✎';
    }
  }

  .entry{
    position: absolute;
    bottom: 2em;
    right: 4em;
    padding: 10px;
    display: flex;
    flex-direction: column;
    background-color: rgba(53, 54, 56, 0.5);
    width: 300px;
    height: 160px;
    z-index: 9999;
    background-color: white;
    box-shadow: 0 0 25px #aaa;
    border-radius: 4px;
    

    input,textarea {
      letter-spacing: 2px;
      margin-top: 5px;
      font-family: inherit;
      line-height: 1;
      padding: 5px;
      border: 1px solid #aad3df;
    }

    textarea {
      letter-spacing: unset;
      flex: 1;
      resize: none;
    }
  }

  .ol-zoom {
    top: unset;
    left: unset;
    bottom: 2em;
    right: .5em;
  }
  .ol-rotate {
    top: unset;
    left: unset;
    bottom: 11em;
    right: 0.6em;
  }
  .ol-control {
    background: none;
    button {
      background-color: rgba(53, 54, 56, 0.5);
      width: 2em;
      height: 2em;
    }
  }
}

h1,h2,h3{
  margin: 0;
}

.banner{
  .logo {
    user-select: none;
    position: fixed;
    top: 2em;
    left: 2em;
    height: 64px;
    display: flex;
    align-items: center;

    img{
      height: 100%;
      margin-right: 20px;
    }

    h2,h3{
      text-shadow: 0 0 3px #fff;
    }
  }

  .box{
    box-shadow: 0 0 40px #6f6f6f82;
    border-radius: 5px;
    position: fixed;
    bottom: -5px;
    padding-bottom: 10px;
    left: 2em;
    min-width: 16vw;
    max-height: 70vh;
    background: #f5f5f5ff;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    font-size: calc(13px + (16 - 14) * ((100vw - 300px) / (1600 - 300)));
    >div{
      margin: 1em;
    }
  }
  .a{ background-color: rgba(31, 163, 35); }
  .b{ background-color: rgba(48, 202, 52); }
  .c{ background-color: rgba(128, 222, 98); }
  .d{ background-color: rgba(186, 222, 98); }
  .e{ background-color: rgba(232, 215, 87); }
  .f{ background-color: rgba(236, 136, 33); }
  .g{ background-color: rgba(236, 70, 33); }
  .h{ background-color: rgba(234, 22, 22); }

  table {   
    width: 100%;
    td {
      text-align: center;
      padding: 0 10px;

      &.legend {
        &::before {
          display: block;
          content: ' ';
          width: 40px;
          height: 20px;
        }
      }
    }

    &.info {
      margin-bottom: 20px;
      th {
        text-align: left;
        opacity: .5;
      }
      td {
        text-align: right;
        font-weight: bold;
      }
    }
  }

  .search{
    position: fixed;
    top: 2em;
    right: 2em;
    display: flex;
    font-size: calc(13px + (16 - 14) * ((100vw - 300px) / (1600 - 300)));

    input {
      font: inherit;
      padding: 10px 20px;
      width: 12vw;
      flex: 1;
      line-height: 2;
      background: #f5f5f5ff;
      border: none;
      border-radius: 8px;
      box-shadow: 0 0 40px #6f6f6f82;
      font-size: 110%;
      font-weight: bold;
      text-align: center;
    }
  }
  
}

/*
-1.309403, 100.549239
*/